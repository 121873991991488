export default {
  components: {
    // Componentes dinámicos. Acá se listan TODOS, pero sólo se carga
    // el determinado desde servidor en: playlist.experience.controller_component
    error: () =>
      import("@/components/controllers/ErrorController.vue"),
    basicPage: () =>
      import("@/components/controllers/infoTemplates/BasicPage.vue"),
    immersive: () =>
      import("@/components/controllers/infoTemplates/PanoramaInfo.vue"),
    Iframe: () =>
      import("@/components/controllers/infoTemplates/IframeInfo.vue"),
    basicPageSlider: () =>
      import("@/components/controllers/infoTemplates/BasicPageSlider.vue"),
    view3d: () =>
      import("@/components/controllers/infoTemplates/view3dPage.vue"),
  },
  GetComponent(blockType) {
    if (blockType == "") {
      return this.components.error;
    }
    const components = Object.keys(this.components);
    if (!components.includes(blockType)) {
      console.warn(`El componente ${blockType} no está registrado`);
      return this.components.error;
    }
    return this.components[blockType];
  },
};
