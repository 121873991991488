var shuffleSeed = require("shuffle-seed");

function map(value, in_min, in_max, out_min, out_max, clamp = true) {
  let val =
    ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  if (clamp) {
    return this.clamp(val, 0, 1);
  }
  return val;
}

function clamp(input, min, max) {
  return input < min ? min : input > max ? max : input;
}

const removeElement = (array, itemId) => {
  const newArr = array.filter((el) => el.dbid !== itemId)
  return newArr

};

const shuffleArray = (array) => {
  return shuffleSeed.shuffle(array, Date.now());
};

const roundTo = (num, decimals = 2) => {
  return parseFloat(num.toFixed(decimals))
}

export { map, clamp, removeElement, shuffleArray, roundTo };